import request from '../request';

export default {
    getHistory(params) {
        const { ASC, order_by, service_type } = params;
        return request.get({
            url: `/user/history?ASC=${ASC}&order_by=${order_by}&service_type=${service_type}`,
        })
    },
    getResultById(email, id) {
        return request.post({
            url: `/record/detail/${id}`,
            data: {
                email
            }
        })
    }
};