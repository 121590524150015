<template>
  <div class="rowcenter background">
    <div class="columncenter">
      <div class="background-img"></div>
      <form id="uploadform" enctype="multipart/form-data">
        <input type="file" name="upload" id="uploadinput" accept=".csv" @change.prevent="submitform"
          style="display: none" />
      </form>
      <div style="z-index: 1" class="inputs">
        <div class="rowcenter" style="width: 100%">
          <div class="rowcenter" style="position: relative; width: 615px">
            <div style="
                position: absolute;
                left: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
              ">
              <img src="../assets/singleprediction/arrow2.png" style="height: 20pt; transform: rotate(180deg)"
                @click="gotosingle" />
            </div>
            <h1 style="font-size: 32pt; color: white">Batch Predicting</h1>
          </div>
        </div>
        <div class="rowcenter">
          <div class="text-area">
            <textarea class="input1"
              placeholder="Please enter mutiple molecula SMILES seperated with space or carriage return or upload file instead."
              v-model="molecularFormula" @input="ifinput" id="mfinput"></textarea>

            <el-button class="text-area-btn" v-if="ifshow" style="margin-right: 90px" type="text"
              @click="downloadExample">
              <el-icon>
                <download />
              </el-icon>
              <div style="margin-left: 9px; font-size: 10pt">Example</div>
            </el-button>
            <el-button class="text-area-btn" v-if="ifshow" style="margin-right: 16px" @click="uploadfile" type="text">
              <el-icon>
                <upload />
              </el-icon>
              <div style="margin-left: 0px; font-size: 10pt">Upload</div>
            </el-button>
          </div>
        </div>
        <div class="radios">
          <div class="input" v-for="item in modeArr" :key="item.mode">
            <input :id="item.mode" type="radio" name="radio" @click="setmode(item.mode)" /><label class="text"
              @click="setmode(item.mode)" :for="item.mode">{{ item.name }}</label>
          </div>
        </div>
        <div class="rowcenter">
          <img style="width: 1006px; margin-top: 38px" src="../assets/singleprediction/photo1.png" />
        </div>
        <div class="rowcenter">
          <el-button class="button1" onmouseover="this.className='button1'" onmouseout="this.className='button'"
            @click.stop="onSubmit">
            Submit
          </el-button>
        </div>
      </div>
    </div>
    <predict-popper v-show="showPopper" :infos="popperInfo" @onSubmit="onSubmitPopper" @onCancel="onCancelPopper">
    </predict-popper>
    <!-- // <Loading v-show="isLoading"></Loading> -->
  </div>
</template>

<script>
// // import loadingfrom "../components/loading.vue";
import { BatchApi } from "../api/index";
import popper from "../components/popper/predict";

export default {
  name: "Batchinput",
  data() {
    return {
      molecularFormula: "",
      smilesFile: null,
      score: {},
      mode: "virus_cell",
      filename: "",
      modelinfo: {},
      ifshow: 1,
      smilesinfo: {},
      predictmode: 0, // 批量预测的模式 0为输入多个分子式进行预测 1为直接上传分子式的文档进行预测
      modeArr: [
        { name: "Cancer Cell Lines", mode: "cancer_cell" },
        { name: "NCI60 Cancer Cells", mode: "nci60_cancer_cell" },
        // { name: "Virus Target", mode: "virus_target" },
        // { name: "Virus Cell Lines", mode: "virus_cell" },
        { name: "Anti-cancer Targets", mode: "cancer_target" },
        { name: "Multitask NCI60 Cells", mode: "multitask" }
        // { name: "Kinases", mode: "kinase" },
      ],
      taskName: null,
      email: null,
      showPopper: false,
      // // isLoading: false,
      popperInfo: {},
    };
  },
  components: {
    "predict-popper": popper,
    // // Loading: loading,
  },
  methods: {
    setmode(data) {
      this.mode = data;
    },
    ifinput() {
      if (this.molecularFormula != "") this.ifshow = 0;
      else this.ifshow = 1;
    },
    uploadfile() {
      document.getElementById("uploadinput").click();
    },
    gotosingle() {
      this.$router.replace("/service/activity-prediction/single");
    },
    submitform(e) {
      //处理上传的分子式文档
      const file = e.target.files;
      const filename = e.target.files[0]["name"];
      this.filename = filename;
      const format = filename.substr(filename.lastIndexOf(".") + 1);
      if (format == "csv") {
        const fd = new FormData();
        fd.append("file", file[0]);
        this.smilesFile = file[0];
        console.log("upload success");
        document.getElementById("mfinput").disabled = "disabled";
        document.getElementById("mfinput").placeholder = this.filename;
        document.getElementById("mfinput").style =
          "background:white;color:white";
        this.predictmode = 1;
      } else {
        this.$store.commit("alertMsg", {
          msg: "Please upload a file in CSV format!",
          type: "error",
        });
      }
    },
    checkForm() {
      if (
        !this.smilesFile &&
        (!this.molecularFormula ||
          this.molecularFormula == "" ||
          this.molecularFormula.trim() == "")
      ) {
        this.$store.commit("alertMsg", {
          msg: "Enter molecular formula or upload a csv file.",
          type: "error",
        });
        return false;
      }
      return true;
    },
    getSmileInfo() {
      BatchApi.getSmileInfo({ smiles: this.molecularFormula }) //先获取分子信息（直接输入分子式）
        .then((res) => {
          if (res.code == 200) {
            console.log("获取分子信息", res.data);
            this.smilesinfo = res.data;
          }
        });
    },
    // getSmileInfoByFile() {
    //   BatchApi.getSmileInfoWithFile(this.smilesFile) //先获取分子信息（输入文档）
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.smilesinfo = res.data;
    //       }
    //     });
    // },
    checkResponse(res) {
      if (res.code == 200) {
        this.$store.commit("alertMsg", {
          msg: `Submit sucessfully. The result will sent ${this.email} when finished.`,
          type: "ok",
        });
      } else {
        this.$store.commit("alertMsg", {
          msg: res.msg,
          type: "error",
        });
      }
    },
    getModelInfo() {
      BatchApi.getModelInfo({ mode: this.mode }).then((res) => {
        //获取成功后进一步获取模型的信息
        if (res.code == 200) {
          console.log("获取模型信息", res.data);
          this.modelinfo = res.data;
        }
      });
    },
    getScore() {
      this.$store.commit("startLoading");
      BatchApi.getActivityScore({
        mode: this.mode,
        smiles: this.molecularFormula,
        email: this.email,
        title: this.taskName,
      })
        .then((res) => {
          //获取成功后进一步获取模型预测分数
          this.checkResponse(res);
          console.log(res);
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        })
        .finally(() => {
          this.$store.commit("endLoading");
        });
    },
    getScoreByFile() {
      this.$store.commit("startLoading");
      console.log("isloading");
      const formData = new FormData();
      console.log(this.mode);
      formData.append("file", this.smilesFile);
      formData.append("title", this.taskName);
      formData.append("email", this.email);
      BatchApi.getBatchActivityScore(this.mode, formData)
        .then((res) => {
          console.log(res);
          //获取成功后进一步获取模型预测分数
          this.checkResponse(res);
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        })
        .finally(() => {
          this.$store.commit("endLoading");
        });
    },
    batchpredict() {
      if (this.predictmode == 0) {
        this.getScore();
      } else {
        this.getScoreByFile();
      }
    },
    onSubmit() {
      if (this.checkForm()) {
        this.popperInfo = {
          Classification: this.getModeName(this.mode),
        };
        if (this.predictmode == 1) {
          this.popperInfo["File"] = this.filename;
        } else {
          this.popperInfo["SMILES"] = this.molecularFormula;
        }
        this.showPopper = true;
      }
    },
    predict() {
      if (this.predictmode == 0) this.getScore();
      else this.getScoreByFile();
    },
    onSubmitPopper(task, email) {
      this.taskName = task;
      this.email = email;
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (reg.test(this.email)) {
        this.predict();
        this.showPopper = false;
      } else {
        this.$store.commit("alertMsg", {
          msg: "Wrong email format!",
          type: "error",
        });
      }
    },
    onCancelPopper() {
      this.showPopper = false;
    },
    downloadExample() {
      window.open(
        this.$store.state.downloadUrl + "/media/files/Batch_Example.csv"
      );
    },
    getModeName(mode) {
      for (let i = 0; i < this.modeArr.length; i++) {
        if (this.modeArr[i].mode == mode) return this.modeArr[i].name;
      }
    },
  },
};
</script>

<style scoped>
.columncenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rowcenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.background-img {
  z-index: 1;
  background-image: url(../assets/singleprediction/big_logo.png);
  background-size: 60vmax;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.background {
  /* background: linear-gradient(to top, var(--color1-dark), var(--color1-light)); */
  height: 100vh;
  background-size: 100% 100%;
}

.radios {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  /* background-color: blue; */
  justify-content: space-around;
}

.input {
  margin-top: 20px;
  /* background-color: red; */
  width: 40%;
}

input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

input[type="radio"]+label::before {
  content: "\a0";
  /*不换行空格*/
  display: inline-block;
  vertical-align: 0.2em;
  width: 14px;
  height: 14px;
  margin-right: 19px;
  border-radius: 3px;
  background-color: whitesmoke;
  text-indent: 0.15em;
  line-height: 0.65;
  /*行高不加单位，子元素将继承数字乘以自身字体尺寸而非父元素行高*/
}

input[type="radio"]:checked+label::before {
  content: "\2713";
  background-color: skyblue;
}

.spacebewteen {
  width: 100%;
  display: flex;
  /* background: pink; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.input1 {
  font-size: 14pt;
  margin-top: 38px;
  width: 577px;
  outline: none;
  height: 208px;
  padding-bottom: 19px;
  padding: 19px;
  border-radius: 10px;
}

.button1 {
  margin-top: 22px;
  width: 327.5px;
  height: 47px;
  color: black;
  /* font-weight: bold; */
  font-size: 20pt;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(to left, #e8f4ff, #8bbbed);
}

.button {
  margin-top: 22px;
  width: 327.5px;
  height: 47px;
  color: black;
  /* font-weight: bold; */
  font-size: 20pt;
  border: none;
  border-radius: 10px;
  background: linear-gradient(to right, #8bbbed, #e8f4ff);
}

.text {
  font-size: 14pt;
  color: white;
}

.text-area {
  position: relative;
}

.text-area-btn {
  position: absolute;
  right: 0px;
  color: gray;
  bottom: 9px;
}
</style>
