import request from "../request"

export default {
    getModeInfo: (data) => {
        return request.get({
            url: '/prediction/model_info?mode=' + data.mode,
            data: data,
        })
    },

    getSmileInfo: (data) => {
        return request.post({
            url: '/prediction/basic_info',
            data: data,
        })
    },

    getSmileInfoWithFile: (data) => {
        return request.filepost({
            url: '/prediction/basic_info',
            data: data,
        })
    },

    getActivityScore: (data) => {
        return request.post({
            url: '/prediction/predict_without_file/' + data.mode,
            data: data,
        })
    },
    getBatchActivityScore: (mode, data) => {
        return request.filepost({
            url: '/prediction/predict_with_file/' + mode,
            data: data,
        })
    },
}