import request from "../request"

export default {
    getSimilarity:(data) =>{
        return request.post({
            url: '/similarity/',
            data:data
        })
    }
    
}