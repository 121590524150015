import request from "../request"

export default {
    getcancerData:(data) =>{
        return request.testpost({
            url: '/cell/_search',
            data:data
        })
    },
    getantiData:(data) =>{
        return request.testpost({
            url: '/target/_search',
            data:data
        })
    },
    getnciData:(data) =>{
        return request.testpost({
            url: '/nci60/_search',
            data:data
        })
    }
}