import request from '../request';


export default {
    select(mode, data) {
        return request.post({
            url: `/selection/${mode}`,
            data: data,
        })
    },
    selectWithFile(mode, data) {
        return request.filepost({
            url: `/selection/${mode}`,
            data: data,
        })
    },
    getmolepicture(data){
        return request.get({
            url: '/selection/draw_molecular?id=' + data.id + '&page_num=' + data.num+"&per_page="+data.page,
        })
    }
}